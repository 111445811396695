import Link from 'next/link'

import styles from './../../../components/_footer.module.scss'
import SocialMenu from '../../../components/SocialMenu'
import { socialLinks } from '../../../components/Footer'
import { t } from '../../../utils'
import { Logo } from '../../../components/Logo'
import Image from 'next/legacy/image'
import labLogoImg from '@public/images/lab_logo.png'
import classNames from 'classnames'
import { useContext } from 'react'
import { ModalsContext } from '../../../utils/modals'
import { useAuth } from '../../../utils/auth'
import { labPath } from '../../../utils/routeHelpers'

const Footer: React.FC = () => {
  const { openModal, closeModal } = useContext(ModalsContext)
  const { isAuthenticated } = useAuth()
  const openSignInModal = () => {
    openModal({
      name: 'SignIn',
      props: {
        onClose: closeModal
      },
      modalTitle: t('Sign In')
    })
  }
  return (
    <div className="section theme-color-wrapper is-hidden-print">
      <footer
        role="contentifno"
        className={classNames('container', styles.footer, styles.labFooter)}
      >
        <div className={styles.labFooterLogoContainer}>
          <Link href="/" prefetch={false} className={styles.logo}>
            <Logo width={'100%'} height={'100%'} />
          </Link>
          <Link href={labPath()} prefetch={false} className={styles.labLogo}>
            <Image
              src={labLogoImg}
              alt="Worship Online Lab"
              width={156}
              height={83}
            />
          </Link>
        </div>

        <div
          className={classNames(
            styles.footerContainer,
            styles.labFooterContainer
          )}
        >
          <nav className={styles.nav}>
            <ul className={`${styles.navMenu} is-clearfix`}>
              <li>
                {/* TODO: Courses & Learning link */}
                <Link href="" prefetch={false}>
                  {t('Courses & Learning')}
                </Link>
              </li>
              <li>
                {/* TODO: community link */}
                <Link href="" prefetch={false}>
                  {t('Community')}
                </Link>
              </li>
              {!isAuthenticated && (
                <>
                  <li>
                    <Link
                      href="https://worshiponline.com/pricing/"
                      prefetch={false}
                    >
                      {t('Sign Up')}
                    </Link>
                  </li>
                  <li>
                    <span
                      className={styles.footerLink}
                      onClick={openSignInModal}
                    >
                      {t('Login')}
                    </span>
                  </li>
                </>
              )}
            </ul>
          </nav>

          <SocialMenu links={socialLinks} />

          <div className={styles.copyright}>
            © {new Date().getFullYear()} {t('Worship Online')}
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
